.wrapper {
  max-width:100%;
  margin: 0 250px;
  @include media-max($xl){
    margin: 0 180px;
  }
  @include media-max($lg){
    margin: 0 100px;

  }
  @include media-max($md){
    margin: 0 50px;
  }
  @include media-max($sm){
    margin: 0 8px;
  }
}

.wrapper_padding {
  max-width:100%;
  padding: 0 250px;
  @include media-max($xl){
    padding: 0 200px;
  }
  @include media-max($md){
    padding: 0 50px;

  }
  @include media-max($sm){
    padding: 0 15px;
  }
}



.mobile_open,
.the_best_lawyer,
.sub_nav,
#nav_mobile {
  @include media-min($md) {
    display: none;
  }
}
#nav {
  @include media-max($md){
    display: none;
  }
}
.the_best_lawyer {
  font-size: 21px;
  font-weight: 900;
  white-space: nowrap;
}
.page_title {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: rem(35px);
  font-family: 'Roboto Condensed', sans-serif;
  @include media-max($sm) {
    min-width: auto;
  }
  span:nth-of-type(1) {
    font-size: rem(36px);
  }
  span {
    color:$darkblue;
    text-transform: uppercase;
    text-align: center;
    display: block;
    font-size: 20px;
    
    @include media-max(1450px) {
      font-size: 16px;
    }
    @include media-max($sm) {
      font-size: 14px;
    }
  }
}
.paragraph {
  margin: 25px 0 15px 25px;
  @include media-max($sm) {
    margin: 25px 0 15px 0;

  }
}
.form_message {
  p, a {
    display: block;
    margin: 50px auto;
    text-align: center;
  }
  p {
    font-size: rem(24px);
  }
  a {
    transition: .3s;
    &:hover {
      color: black;
      text-decoration: underline;
    }
  }
    
}
#cookies {
  
}

.cookies_hidden {
  display: none;
}
.totop {
  position: absolute;
  bottom: 10px;
  background-color: $darkblue;
  right: 10px;
  z-index: 5;
  color: #fff;
  border-radius: 20px;
  padding: 10px;
  &:hover {
    transform: translateY(-6px);
    
  }
}