.page_content { 
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
  padding: 35px 45px;
  height: 100%;
  text-align: justify;
  background-image: url('/assets/images/temida.jpg');
  background-size: cover;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.911);
    z-index: -1;
    border-radius: 15px;
  }
  @include media-max($sm) {
    padding: 20px 15px;
  }
  p, li {
    font-size: 18px;
    color: $darkblue;
    @include media-max($sm) {
      font-size: 16px;

    }
  }
  ul {
    text-align: left;
  }
}

section.page_content_wrapper {
  position: relative;
  margin-bottom: rem(60px);
  z-index: 1;
}
.section {
  margin-bottom: 40px;
  line-height: rem(35px);
  li {
    list-style: disc;
    margin-left: 25px;
  }
}
.section.contact {
  .uk-input,
  textarea,
  button{
    border-radius: 10px;
    background: transparent;
    border-color: $darkblue;
  }
  #first_name,
  #last_name {
    text-transform: capitalize;
  }
  form {
    justify-content: center;
    margin-top: rem(20px);
  }
  button {
    margin: 0 auto;
    background: $darkblue;
    color: #fff;
    font-weight: 600;
    transition: all .1s;
    font-size: 18px;
    &:hover {
      -webkit-box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.20);
      -moz-box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.20);
      box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.20);
    }
  }

  .contact_links {
    display: flex;
    flex-direction: column;
    gap: 20px;
   

    span.facebook {
      color: rgb(59,89,152);
    }
    span.linkedin {
      color:  #0e76a8;
    }
    a {
      display: grid;
      grid-template-columns: 50px 1fr;
      align-items: center;
      width: max-content;
      &:hover {
        transform: translateX(5px);
        color: $darkblue;
      }
    }
  }
  .data {
    font-size: 12px;
  }
}
.content_title {
  display: inline-block;
  font-size: rem(38px);
  font-weight: bold;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: calc(100% - 2px);
    height: 1px;
    background-color: $darkblue;
    width: 100%;
  }
  @include media-max ($md) {
    font-size: rem(24px);
  }
  @include media-max($sm) {
    margin-bottom: 15px;
    padding-bottom: 15px;

  }
}

#google_map {
  width: 100%;
}

.bottom_links {
  // border-top: 2px solid $darkblue;
  margin-top: 20px 0;
  padding-bottom: rem(40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  span {
    margin-right: 15px;
  }
  a {
    width: max-content;
    transition: .3s all ease-in-out;
    
    &:hover {
      // background-color: $darkblue;
      color: red;
    }
 
  }
}