.uk-slideshow-items {
  img {
    // max-height: 200px;
    object-fit: cover;
  }
}
.uk-offcanvas-bar {
  background: #002f5df7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.uk-modal-dialog {
  width: 100%;
  height: 100%;
}