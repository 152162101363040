// ------------------------------------------------------------
// @include font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff);
// Use only woff2, woff formats, both are required
// Example: @include font-face('Font Name', $font-dir+'font-file-name', 400);
// @include font-face('Open Sans', $open-sans-dir+'OpenSans-Regular', 400);
// ------------------------------------------------------------
// Default fonts directory
$fonts-directory: '../assets/fonts/';
$font-dir:'font-dir/';

// example font-face


@font-face {
  font-display: swap;
  font-family: 'Roboto Slab';
  src: url($fonts-directory + 'RobotoSlab-Light.eot');
  src: url($fonts-directory + 'RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
      url($fonts-directory + 'RobotoSlab-Light.woff2') format('woff2'),
      url($fonts-directory + 'RobotoSlab-Light.woff') format('woff'),
      url($fonts-directory + 'RobotoSlab-Light.ttf') format('truetype'),
      url($fonts-directory + 'RobotoSlab-Light.svg#RobotoSlab-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto Slab';
  src: url($fonts-directory + 'RobotoSlab-Regular.eot');
  src: url($fonts-directory + 'RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
      url($fonts-directory + 'RobotoSlab-Regular.woff2') format('woff2'),
      url($fonts-directory + 'RobotoSlab-Regular.woff') format('woff'),
      url($fonts-directory + 'RobotoSlab-Regular.ttf') format('truetype'),
      url($fonts-directory + 'RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}


@font-face {
    font-display: swap;
    font-family: 'Roboto Slab';
    src: url($fonts-directory + 'RobotoSlab-Bold.eot');
    src: url($fonts-directory + 'RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
        url($fonts-directory + 'RobotoSlab-Bold.woff2') format('woff2'),
        url($fonts-directory + 'RobotoSlab-Bold.woff') format('woff'),
        url($fonts-directory + 'RobotoSlab-Bold.ttf') format('truetype'),
        url($fonts-directory + 'RobotoSlab-Bold.svg#RobotoSlab-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
