.load_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
  background: #fff;
  z-index: 1000;
 
	
	.loading-bar {
		display: flex;
		height: 10px;
		width: 100%;
		max-width: 320px;
		box-shadow: inset 0px 0px 0px 1px #C8C8CD;
		border-radius: 50px;
		overflow: hidden;
		
		@keyframes progress-animation {
			0% {width: 0%;}		
			100% {width: 100%;}
		}

		.progress-bar {
			display: flex;
			height: 100%;
			width: 100%;
      background: rgb(26,127,232);
      background: linear-gradient(90deg, rgba(26,127,232,1) 0%, rgba(0,47,93,1) 100%);
			animation: progress-animation 1.5s ease-in-out;
		}
	}
}