footer {
  padding: 35px 0;
  background-color: $darkblue;
  color: #fff;
    position: relative;
  z-index: 1;
  h3 {    
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
    margin-bottom: 0;
  }
  a {
    font-size: 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    @include media-max($sm) {
      font-size: 14px;
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    @include media-max($md) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: rem(30px);
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      address {
        padding-top: 15px;
      }
    }
    .links {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      flex-wrap: wrap; 
      gap: 20px;
      a:not(.privacy) {
        // margin-left: rem(40px);
        border-radius: 10px;
        padding: 10px 20px;
        border: 1px solid #b9b9b925;
        transition: .2s all ease-in-out;
       
        @include media-max($md) {
          margin-left: 0;
          // margin-right: rem(40px);
        }
        &:hover {
          transform: translateY(-3px);
          -webkit-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, .1);
          -moz-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, .1);
          box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, .1);
        }
      }
      a:first-child {
        margin-left: 0;
      }
      @include media-max($md) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include media-max($sm) {
        row-gap: rem(30px);
      }
    
    }
 
  }
}
