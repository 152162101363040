header {
  position: relative;
  margin-bottom: rem(-180px);
   .kirp_logo {
      user-select: none;  
      @include media-max($sm) {
        width: 80%;
      }
  }
  @include media-max($sm) {
    margin-bottom: rem(-300px);
  }
}
.nav_wrapper { 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(0 190px);
  position: relative;
  z-index: 1;
  @include media-max ($lg) {
    padding: 0 20px;
  }
}
.nav {
  margin-top: rem(35px); 
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: $darkblue;
  z-index: 2;
  width: 100%;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
  padding: 20px 0;
  position: relative;
  color: rgba(128, 128, 128, 0.8);
  &_ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    position: relative;
    z-index: 1;
    gap: 20px;
    li {
      transition: .3s all;
    }
  
    li.uk-active {
      a {
        font-weight: bold;
        color: $darkblue;

      }
    }
    a {
      display: block;
      padding: 10px 20px;
      @media (min-width: $lg) and (max-width: $xl) {
        padding: 5px 10px;

      }
    }
  }
  .highlight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: calc(100% - 40px);
    z-index: 0;
    border-radius: 10px;
    box-sizing: border-box !important;
    display:inline-block;
    width:30px;
    background: #ffffffd3;
    transition:all .4s linear;


  }
}
.slider_container {
  height: rem(550px);
  padding: rem(0 190px);
  position: relative;
 
  @include media-max($lg) {
    height: 400px;
    padding: rem(0 20px);
  }
  @include media-max($md) {
    padding: 0;
  }
  .tr_slider{
    height: 100%;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.14);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    &_ul {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;    
      min-height: 100% !important;
      @include media-max($md) {
        margin-top: rem(25px);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      }
    }
  }
}
.mobile_open {
  margin-left: auto;
  margin-right: 20px;
}
.mobile_nav_ul {
  font-size: 18px;
  li {
    margin-bottom: rem(25px);
    padding-bottom: 5px;
  }
  li.uk-active {
    border-bottom: 1px solid $gray;
  }
}
img.logo_kirp_mobile {
  max-width: 50%;  
  margin-top: -100px;
  margin-bottom: rem(60px);
}
.sub_nav {
  @include media-max($md) {
    position: absolute;
    right: 20px;
    top: 20px;
    // margin: 25px 0 0 auto;
    color: #fff;
  }
}
// 

.hamburger .line{
  width: 35px;
  height: 4px;
  background-color: $darkblue;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}


#hamburger-3.is-active .line:nth-child(1),
#hamburger-3.is-active .line:nth-child(3){
  width: 20px;
}

#hamburger-3.is-active .line:nth-child(1){
  -webkit-transform: translate(-10px, 4px) rotate(-45deg);
  -ms-transform: translate(-10px, 4px) rotate(-45deg);
  -o-transform: translate(-10px, 4px) rotate(-45deg);
  transform: translate(-10px, 4px) rotate(-45deg);
}

#hamburger-3.is-active .line:nth-child(3){
  -webkit-transform: translate(-10px, -4px) rotate(45deg);
  -ms-transform: translate(-10px, -4px) rotate(45deg);
  -o-transform: translate(-10px, -4px) rotate(45deg);
  transform: translate(-10px, -4px) rotate(45deg);
}